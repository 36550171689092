<template>
  <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>
  <div v-else class="programs">
    <div class="page-head" style="display: none;">
      <div
        class="search-bar"
        :class="{ 'yf-filter--modal': filterIsOpenSearch }"
      >
        <div class="yf-filters-from__wrap">
          <div class="yf-filters-from__mobile-title hidden-desktop">
            <p class="yf-filters-from__mobile-title--txt hidden-desktop">
              Поиск
            </p>
            <span class="close-filters" @click="switchSearch"></span>
          </div>
          <input
            placeholder="По названию тренировки"
            type="text"
            v-model="filter.search"
            @input="search"
          />
          <span class="search-icon"></span>
        </div>
      </div>
    </div>
    <div class="flex flex--wrap flex--space-between" data-sticky-container>
      <div class="columne-3">
        <div
          class="card card-custom sticky"
          data-sticky="true"
          data-margin-top="140"
          data-sticky-for="1023"
          data-sticky-class="stickyjs"
        >
          <div class="card-body p-5">
            <p class="card-main-title">Фильтры</p>
            <div
              class="accordion accordion-light accordion-toggle-arrow"
              id="accordion_training_id"
            >
              <div
                class="card"
                v-for="(trainingFilters, index) of trainingsFiltersData"
                :key="`training-filter-id-${index}`"
                v-show="trainingFilters.length > 0"
              >
                <div class="card-header" :key="`headingOne4-${index}`">
                  <div
                    class="card-title collapsed"
                    data-toggle="collapse"
                    :data-target="`#training_filter_collapse_${index}`"
                  >
                    {{ " " }}
                    <p class="filter-icon" v-if="index === 'body_parts'">
                      <i class="fas fa-crosshairs"></i>
                      <span>Цель</span>
                    </p>
                    <p class="filter-icon" v-if="index === 'trainers'">
                      <i class="fas fa-user-check"></i>
                      <span>Тренер</span>
                    </p>
                    <p class="filter-icon" v-if="index === 'time'">
                      <i class="far fa-clock"></i>
                      <span>Длительность</span>
                    </p>
                  </div>
                </div>
                <div
                  :id="`training_filter_collapse_${index}`"
                  class="collapse"
                  data-parent="#accordion_training_id"
                >
                  <perfect-scrollbar
                    style="max-height: 300px; position: relative;"
                  >
                    <div class="card-body">
                      <div class="checkbox-list">
                        <label
                          class="checkbox checkbox-success"
                          v-for="trainingFilter of trainingFilters"
                          :key="`training-filter-${index}-${trainingFilter.id}`"
                        >
                          <input
                            type="checkbox"
                            :value="trainingFilter.id"
                            @change="changeFilter"
                            v-model="filter[index]"
                          />
                          <span></span>
                          {{ trainingFilter.value }}
                        </label>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columne-divider--15 hide-on--tablet"></div>
      <div class="columne-7 margin--m-8 margin__mobile-0">
        <div class="flex flex--wrap">
          <div v-if="trainingsList.length === 0" style="margin: 0 auto;">
            По заданным вами критериям ничего не найдено, измени, пожалуйста,
            {{ " " }}
            настройки поиска
          </div>
          <div
            else
            v-for="training of trainingsList"
            :key="training.id"
            class="trainings-item"
          >
            <div class="trainings-item__header">
              <p class="trainings-item__header--name">
                {{ training.trainer_name }}
              </p>
              <p class="trainings-item__header--users-couter">
                {{ training.count_done }}
              </p>
            </div>
            <div class="trainings-item__embed" style="">
              <div
                :id="`training-video-by-id-${training.id}`"
                class="embed-responsive training embed-responsive-16by9"
                :class="{ show: iframeIsOpen }"
                :ref="`js-embed-responsive-training-${training.id}`"
              ></div>
            </div>
            <div
              class="trainings-item__previews"
              :class="{ show: iframeIsOpen }"
              @click="createPlayer(training)"
            >
              <img
                :src="`${$APP_URL}storage/app/previews/${training.id}.jpg`"
                style="width: 100%"
              />
            </div>
            <div class="trainings-item__description">
              <router-link
                :to="`/training/${training.packs[0]?.id}/${training.id}`"
                style="text-decoration: underline; color: #4B4B4B; width: 100%"
              >
                <h5 class="trainings-item__description--title">
                  {{ training.name }}
                </h5>
              </router-link>
              <span
                class="trainings-item__description--level-value"
                :class="`type_${Math.ceil(training.training_level / 3)}`"
              ></span>
              <div class="trainings-item__description--bottom">
                <p class="trainings-item__description--txt">
                  Программа:
                  <router-link
                    :to="`/training/${training.packs[0]?.id}`"
                    style="text-decoration: underline; color: #4B4B4B;"
                  >
                    {{ training.packs[0]?.name }}
                  </router-link>
                </p>
                <p class="trainings-item__description--goal">
                  Цель:
                  <span
                    v-for="(part, index) of training.parts"
                    :key="
                      `part-${training.id}-${training.packs[0]?.id}-${part.id}`
                    "
                    :class="{
                      '': index === 0,
                      '': index > 0
                    }"
                  >
                    {{
                      part.name +
                        (index + 1 === training.parts.length ? "" : ", ")
                    }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import "remodal/dist/remodal.min";
import Sticky from "sticky-js";

import { REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { getYoutubeId } from "@/assets/js/helpers";

import YouTubePlayer from "youtube-player";

import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "PageTrainings",
  components: {
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      iframeIsOpen: false,
      filterIsOpenSearch: false,
      filterIsOpenFilter: false,
      isActive: false,
      isAwaitFilter: false,
      playerYoutubeListener: null,
      filter: {
        search: "",
        trainers: [],
        time: [],
        body_parts: []
      },
      offset: 0,
      limit: 15
    };
  },
  computed: {
    ...mapGetters(["userSubscriptions"]),
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
    trainingsList() {
      return this.$store.getters.trainingsList;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
    trainingsFiltersData() {
      return this.$store.getters.trainingsFiltersData;
    },
    isLoading() {
      return this.$store.getters.trainingsIsLoading;
    }
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Тренировки" }]);
    this.$store.dispatch("getTrainingsFiltersData");
    this.$store.dispatch("getUserSubscriptions");
    this.getTrainingsList();
    this.modalBuyTraining = $("[data-remodal-id='buy-training']").remodal({
      hashTracking: false
    });
    this.showAllFilters();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    imageTriningPreview(training) {
      let imageTriningPreview = "";

      if (training && training.video) {
        imageTriningPreview = `
          https://img.youtube.com/vi/${getYoutubeId(
            training.video
          )}/maxresdefault.jpg
        `;
      }

      return imageTriningPreview;
    },
    handleScroll() {
      let documentHeight = $(document).height();
      let windowHeight = Math.max(
        window.innerHeight,
        document.documentElement.clientHeight,
        document.body.clientHeight
      );
      let windowScrollTop = Math.max($(window).scrollTop(), window.scrollY);

      windowScrollTop =
        parseInt(windowScrollTop) >= windowScrollTop
          ? parseInt(windowScrollTop)
          : parseInt(windowScrollTop) + 1;

      if (windowScrollTop >= documentHeight - windowHeight) {
        this.nextPage();
      }
    },
    showAllFilters() {
      $(".collapse")
        .removeData("bs.collapse")
        .collapse("show")
        .collapse({
          parent: "#accordion_training_id",
          toggle: false
        });
    },
    search() {
      const __self = this;

      clearTimeout(this.timeout);

      this.offset = 0;
      this.timeout = setTimeout(() => {
        __self.getTrainingsList();
      }, 1000);
    },
    changeFilter() {
      this.offset = 0;
      this.getTrainingsList();
    },
    nextPage() {
      this.offset = this.offset + this.limit;
      this.getTrainingsList();
    },
    switchSearch() {
      this.filterIsOpenSearch = !this.filterIsOpenSearch;
      this.filterIsOpenFilter = false;
    },
    getTrainingsList() {
      const offset = this.offset;

      if (this.isLoading && this.isLoading === true) {
        return;
      }

      this.$store
        .dispatch("getTrainingsList", {
          trainers: this.filter.trainers,
          time: this.filter.time,
          body_parts: this.filter.body_parts,
          search: this.filter.search,
          offset: this.offset,
          limit: this.limit
        })
        .then(() => {
          const StickyMod = Sticky;
          new StickyMod(".sticky");

          if (offset === 0) {
            window.scrollTo(0, 0);
          }
        });
    },
    createPlayer(training) {
      const videoUrl = `training-video-by-id-${training.id}`;
      this.playerYoutube = new YouTubePlayer(videoUrl, {
        https: "https://www.youtube.com",
        width: 280,
        height: 167,
        videoId: this.getYoutubeId(training.video),
        playerVars: {
          rel: 0,
          showinfo: 0,
          enablejsapi: 1,
          autoplay: 1
        }
      });

      this.playerYoutubeListener = this.playerYoutube.on(
        "stateChange",
        event => {
          switch (event.data) {
            case 0:
              this.logYoutubeEvent(
                getYoutubeId(this.training.video),
                this.training.video,
                3
              );
              break;
            case 1:
              this.logYoutubeEvent(
                getYoutubeId(this.training.video),
                this.training.video,
                1
              );
              break;
            case 2:
              this.logYoutubeEvent(
                getYoutubeId(this.training.video),
                this.training.video,
                2
              );
              break;
            default:
              break;
          }
        }
      );
    },
    setPageData() {
      switch (this.socialIsMyProfile) {
        case true:
          this.pageTitle = "Тренировки - FitStars.ru";
          this.$store.dispatch(SET_BREADCRUMB, [{ title: "Тренировки" }]);

          break;
        case false:
          this.pageTitle = "Тренировки - FitStars.ru";
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: "Тренировки - FitStars.ru"
            }
          ]);

          break;
      }
    },
    getYoutubeId
  }
};
</script>
