<template>
  <!-- <div v-if="comments && coments.length > 0" class="training-comment-list-wr"> -->
  <div v-if="comments && comments.length > 0" class="training-comment-list-wr">
    <div class="comments-top">
      <div class="comments-title">Все отзывы</div>
        <div class="comments-filter">
          <div v-if="order_by == 'popular'" class="comments-filter-bnt">
            <button @click="commentFilter = !commentFilter" href="javascript:void(0)" class="comments-title-filter">
            <img src="@/assets/img/list-filter.svg" alt="">&nbsp; Сначала популярные</button>
            <transition>
                <div class="filter-modal" v-show="commentFilter">
                  <a @click="changeOrdering('created_at')" href="javascript:void(0)"><div class="filter-text">Сначала новые</div></a>
                </div>
            </transition>
          </div>
          <div v-else class="comments-filter-bnt">
            <button @click="commentFilter = !commentFilter" href="javascript:void(0)" class="comments-title-filter">
            <img src="@/assets/img/list-filter.svg" alt="">&nbsp; Сначала новые</button>
            <transition>
                <div class="filter-modal" v-show="commentFilter">
                  <a @click="changeOrdering('popular')" href="javascript:void(0)"><div class="filter-text">Сначала популярные</div></a>
                </div>
            </transition>
          </div>
        </div>
    </div>

    <div v-show="loadingComments" class="training-comment-list__loader">
      <div class="spinner spinner-primary spinner--comments"></div>
    </div>
    
    <div
      :class="[loadingComments && 'list-on-load']"
      class="fs-ptile training-comment-list__item"
      v-for="(comment, indexComment) of comments"
      :key="`comment-data-${indexComment}`"
    >
    
      <div
        v-if="comment && Object.keys(comment).length > 0"
        style="width: 100%"
      >
        <div class="flex">
          <router-link
            class="training-comment-list__item--avatar"
            :to="`/fitness-profile/${comment.user.url_slug}`"
          >
            <img
              v-if="comment.user.avatar"
              class="fs-ptile-img"
              :src="`${comment.user.avatar_image}`"
            />
            <span v-else class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ comment.user.full_name.charAt(0).toUpperCase() }}
              </span>
            </span>
          </router-link>
          <div class="training-comment-list__item--user-info">
            <div class="comment-user-name">{{ comment.user.full_name }}</div>
            <div class="comment-user-data">
              {{ getFormattedDate(comment.created_at, "DD.MM.YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="training-comment-list__item--comment"
          v-html="comment.body"
        ></div>

        <button
          @click="toggleLike(comment)"
          type="button"
          :class="{ liked: comment.is_liked }"
          class="training-comment-list__item--likes"
        >
          <i style="color: #EF16B0" class="flaticon-black flaticon-black--like-disappearance"></i>
          <i :style="`color: ${comment.is_liked ? '#EF16B0' : ''}`" class="flaticon-black mr-2"></i> <template v-if="comment.likes">{{ comment.likes }}</template>
        </button>

        <div
          class="flex"
          v-for="(subComment, iSubComment) of comment.reply.slice()"
          :key="`sub-comment-data-${iSubComment}`"
        >
          <router-link
            class="training-comment-list__item--avatar"
            :to="`/fitness-profile/${subComment.user.url_slug}`"
          >
            <img
              v-if="subComment.user.avatar && subComment.user.avatar.path"
              class="fs-ptile-img"
              :src="`${subComment.user.avatar_image}`"
            />
            <span v-else class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ subComment.user.full_name.charAt(0).toUpperCase() }}
              </span>
            </span>
          </router-link>
          <div class="training-comment-list__item--user-info">
            <div
              class="comment-user-name"
              style="display: flex; align-items: center"
            >
              {{ subComment.user.full_name }}
              <div class="comment-user-data" style="margin-left: 8px">
                {{ getFormattedDate(subComment.created_at, "DD.MM.YYYY") }}
              </div>
            </div>
            <div
              class="training-comment-list__item--comment"
              v-html="subComment.body"
            ></div>

            <button
              @click="toggleLike(subComment)"
              type="button"
              :class="{ liked: subComment.is_liked }"
              class="training-comment-list__item--likes"
            >
              <i style="color: #EF16B0" class="flaticon-black flaticon-black--like-disappearance"></i>
              <i :style="`color: ${subComment.is_liked ? '#EF16B0' : ''}`" class="flaticon-black mr-2"></i> <template v-if="subComment.likes">{{ subComment.likes }}</template>
            </button>
          </div>
        </div>
        <div>
          <div class="training-comment-list__item--form">
            <div class="training-comment-list__item--form__line"></div>
            <textarea
              id="kt_autosize_1"
              class="training-comment-list__item--form__textarea"
              v-model="commentBody[comment.id]"
              rows="1"
              placeholder="Ответить..."
            ></textarea>
            <div
              class="training-comment-list__item--form__send"
              @click="sendComment(comment.id)"
            >
              <i class="menu-icon flaticon2-send-1"></i>
            </div>
            <!-- <div class="flex flex--center flex--wrap flex--sc-end">
              <button class="btn btn--succses" @click="sendComment(comment.id)">
                Отправить
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <div slot="spinner">
        <div class="spinner spinner-center"></div>
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import { getFormattedDate } from "@/assets/js/helpers";

import {
  ADD_COMMENT,
  GET_COMMENTS_LIST,
  TOGGLE_LIKE_COMMENT,
} from "@/core/services/store/comments.module";

export default {
  name: "FSComments",
  components: {
    InfiniteLoading,
  },
  props: {
    commentObject: Object,
    type: Number
  },
  data() {
    return {
      commentBody: [],
      infiniteId: +new Date(),
      commentFilter: false,
      order_by: "popular",
      loadingComments: false,
    };
  },
  computed: {
    comments() {
      return this.$store.getters.commentsList;
    },
    meta() {
      return this.$store.getters.commentsMeta;
    }
  },
  beforeDestroy() {
    this.$root.$off("page_comments__send_comment");
  },
  mounted() {
    let __self__ = this;

    this.$root.$on("page_comments__send_comment", function() {
      __self__.getComments();
    });
    
    this.getComments();
  },
  methods: {
    getFormattedDate,
    getUserAvatar(avatar) {
      if (avatar.path != null) {
        return `${avatar.path}`;
      }

      return null;
    },
    getComments() {
      this.loadingComments = true;
      this.commentFilter = false;
      this.$store
        .dispatch(GET_COMMENTS_LIST, {
          training_id: this.commentObject.id,
          type: this.type,
          order_by: this.order_by,
        })
        .then(() => {
          this.commentBody = [];
          this.infiniteId++;
          this.loadingComments = false;
        });
    },
    changeOrdering(order) {
      this.order_by = order;
      this.getComments();
    },
    moreComments() {
      return this.$store.dispatch(GET_COMMENTS_LIST, {
        page: this.meta?.current_page + 1,
        training_id: this.commentObject.id,
        type: this.type,
        order_by: this.order_by,
      });
    },
    sendComment(parentCommentId) {
      this.$store
        .dispatch(ADD_COMMENT, {
          training_id: this.commentObject.id,
          parent: parentCommentId || null,
          content: this.commentBody[parentCommentId],
          type: this.type
        })
        .then(() => {
          this.commentBody = [];
        });
    },
    toggleLike(comment) {
      this.$store.dispatch(TOGGLE_LIKE_COMMENT, comment);
    },
    infiniteHandler($state) {
      if (this.meta?.current_page < this.meta?.last_page) {
        this.moreComments()
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
  },
  watch: {
    commentObject() {
      this.getComments();
    }
  }
};
</script>

<style>
.training-comment-list__item--likes {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #7E8298;
  margin-bottom: 20px;
}

.training-comment-list__item--likes .flaticon-black--like-disappearance {
  display: none;
  position: absolute;
  color: rgb(239, 22, 176);
}

.training-comment-list__item--likes.liked .flaticon-black--like-disappearance {
  display: block;
  animation: like-disappearance .4s forwards;
}

@keyframes like-disappearance {
  100% {
    transform: translateY(-20px) scale(.7);
    opacity: 0;
  }
}
.comments-top {
  display: flex;
}
.comments-filter {
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 15px;
  align-items: flex-start;
}
.filter-modal {
  background-color: #FFFFFF;
  position: absolute;
  margin-top: 10px;
  right: 12px;
  width: 182px;
  height: 52px;
  padding: 14px 0 14px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #4B4B4B;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.comments-filter-bnt {
  margin-right: 11.7px;
}
.comments-title-filter {
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
}

.filter-text {
  padding: 0 12px 0 12px;
  color: #4B4B4B;
}
.filter-text:hover {
  cursor: pointer;
  background-color: #F4F7F9;
}
.training-comment-list__loader {
  position: relative;
  background-color: #EEF0F8;
}
.spinner--comments {
  position: absolute;
  top: 30px;
  left: 50%;
  right: 50%;
}
.list-on-load {
  opacity: 0.5;
}
</style>
