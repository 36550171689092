<template>
  <div class="fs-ptile">
    <div class="fs-ptiles-tape">
      <div class="training-comment">
        <div class="training-comment__title">Оставить отзыв</div>
        <textarea
          id="kt_autosize_1"
          class="training-comment__textarea"
          v-model="comment"
          rows="8"
        ></textarea>
          <div class="grade-block">
            <div v-if="isRecipe" class="grade-block__text">Пожалуйста, оцените рецепт:</div>
            <div v-if="isRecipe" class="grade-block__row">
              <div class="grade-block__item" @click="setRating(1)" :class="{'grade-block__item--active': rating > 0}"></div>
              <div class="grade-block__item" @click="setRating(2)" :class="{'grade-block__item--active': rating > 1}"></div>
              <div class="grade-block__item" @click="setRating(3)" :class="{'grade-block__item--active': rating > 2}"></div>
              <div class="grade-block__item" @click="setRating(4)" :class="{'grade-block__item--active': rating > 3}"></div>
              <div class="grade-block__item" @click="setRating(5)" :class="{'grade-block__item--active': rating > 4}"></div>
            </div>


            <div class="flex flex--center flex--wrap flex--sc-end">
              <button class="btn btn--succses" @click="sendComment">
                Отправить
              </button>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ADD_COMMENT } from "@/core/services/store/comments.module";
import { GET_RECIPE_RATING_USER } from "@/core/services/store/recipe.module";

export default {
  name: "FSComment",
  props: {
    commentObject: Object,
    type: Number
  },
  data() {
    return {
      comment: "",
      rating: null,
    };
  },
  computed: {
    isRecipe() {
      return this.$store.getters.recipeItem;
    },
    ratingUser() {
      return this.$store.getters.recipeRatingUser;
    },
  },
  async created() {
    await this.$store.dispatch(GET_RECIPE_RATING_USER, this.$route.params.id);

    this.setRating(this.ratingUser);
  },
  mounted() {},
  methods: {
    sendComment() {
      this.$store
        .dispatch(ADD_COMMENT, {
          training_id: this.commentObject.id,
          type: this.type,
          parent: null,
          content: this.comment,
          rating: this.rating,
        })
        .then(() => {
          if (this.rating) {
            this.$notify({
              type: "success",
              title:
                "Спасибо, что оценили рецепт"
            });
          }

          this.comment = "";

          this.$root.$emit("page_comments__send_comment", {
            id: this.commentObject.id,
            type: this.type
          });
        });
    },
    setRating(rate) {
      if (this.rating == rate) {
        this.rating = null;
      } else {
        this.rating = rate;
      }
    },
  },
  destroyed() {
    this.$store.dispatch("clearRecipe");
  }
};
</script>

<style>
.grade-block__item {
  background-image: url(../../../assets/img/grade-star-2.svg);
  width: 20px;
  height: 19px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 2px;
}
.grade-block {
  display: flex;
  align-items: center;
}
.grade-block__text {
  margin-right: 8px;
}
.grade-block__row {
  display: flex;
}
.grade-block__item--active {
  background-image: url(../../../assets/img/grade-star-1.svg);
  width: 20px;
  height: 19px;
}

@media screen and (max-width: 500px) {
  .grade-block {
    flex-wrap: wrap

  }
  .flex.flex--center.flex--wrap.flex--sc-end {
    justify-content: start;
    margin-top: 17px;
  }
  .grade-block__row {
    margin-right: 80px;
  }
}

@media screen and (max-width: 410px) {
  .grade-block__row {
    margin-right: auto;
  }
}

@media screen and (max-width: 365px) {
  .grade-block__row {
    margin-right: auto;
  }
  .grade-block__text {
    width: 100px;
  }
}

@media screen and (max-width: 280px) {
  .grade-block__row {
    margin-right: 0px;
  }
  .grade-block__text {
    width: 80px;
  }

}
</style>