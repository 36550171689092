import axios from "axios";
import settings from "@/settings";
import ls from "./ls.service";

const headers = {
  "Content-Type": "application/json"
};

const instance = axios.create({
  headers
});
const notifyAboutError = () => {
  // empty
};

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status == 401) {
      ls.removeItem(settings.ID_TOKEN);
      window.location.href = settings.APP_URL + 'login';
    } else {
      notifyAboutError(error);
      return Promise.reject(error);
    }
  }
);

export default instance;
