<template>
    <div class="upgrade-panel upgrade-panel--new">
        <p class="upgrade-panel__text">
            Зарабатывай звезды и получай подарки в новом личном кабинете!
            <a href="javascript:void(0)" class="upgrade-panel__link" @click="goToNew">Попробовать</a>
        </p>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import settings from "@/settings";
    import {
      ADD_BODY_CLASSNAME
    } from "@/core/services/store/htmlclass.module.js";
    export default {
        name: 'NewPanel',
        computed: {
            ...mapGetters(["newLKInfo"]),
        },
        methods: {
            async goToNew() {
              this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
              await this.$store.dispatch('goToNewLk');
              if (this.newLKInfo && this.newLKInfo.data && this.newLKInfo.data.api_v4_token) {
                window.location.href = `${settings.NEW_APP_URL}?token=${this.newLKInfo.data.api_v4_token}`
              }
            }
        }
    }
</script>

<style>
.upgrade-panel.upgrade-panel--new {
    background-color: #79B6E2;
    display: block;
    padding: 9px 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 740px) {
    .upgrade-panel.upgrade-panel--new {
        height: 44px;
    }
}
.upgrade-panel--new .upgrade-panel__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.56px;
}
.upgrade-panel--new .upgrade-panel__link {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.56px;
    border-bottom: 1px solid #fff;
}
</style>