<template>
  <div class="yf-layout" v-if="isRecipeLoaded"></div>

  <div v-else>
    <div class="yf-container">
      <div class="page-head">
        <router-link class="yf-back-title" to="/recipes"> Назад </router-link>
        <div class="page-head--filters">
          <router-link
            class="nutrition-button--calculator"
            to="/recipes/calculator"
          >
            <span class="nutrition-icon--calculator">Калькулятор питания</span>
          </router-link>

          <router-link class="nutrition-button--settings" to="/new-profile">
            <span class="nutrition-icon--settings">&nbsp;</span>
          </router-link>
        </div>
      </div>

      <div class="nutrition-header-block">
        <div class="nutrition-header-block-info">
          <div class="nutrition-header-block__title">
            {{ recipe.name }}
          </div>
          <div class="rating" v-if="recipe.rating">
            <img src="@/assets/img/recipe/new/Star-large.svg" alt="" />
            {{ Number(recipe.rating).toFixed(1) }}
          </div>
          <div class="nutrition-header-block__sub-title">Ингредиенты:</div>

          <div class="nutrition-header-block-view-item-section">
            <span
              class="nutrition-text--header-block-view-item-section-text nutrition-header-block-view-item-section--item"
              v-for="(ingredient, key) in recipe.ingredients"
              :key="`recipe-ingredients-${key}`"
            >
              {{ ingredient.name }} -
              {{
                !ingredient.weight
                  ? `${ingredient.unit}`
                  : `${ingredient.weight} ${ingredient.unit}`
              }}
            </span>
          </div>
        </div>

        <div class="nutrition-header-block-preview">
          <iframe
            class="iframe"
            :src="`${recipe.video_link}?showinfo=0&rel=0`"
            allowfullscreen
            frameborder="0"
            height="265"
          >
          </iframe>
        </div>
      </div>

      <div class="nutrition-header-block-mobile">
        <div class="nutrition-header-block-mobile__header">
          <div class="page-title">
            <h2>{{ recipe.name }}</h2>
          </div>
          <div class="rating" v-if="recipe.rating">
            <img src="@/assets/img/recipe/new/Star-large.svg" alt="" />
            {{ Number(recipe.rating).toFixed(1) }}
          </div>
        </div>

        <div class="nutrition-header-block-preview--view-item">
          <iframe
            class="iframe yf-border-radius"
            :src="`${recipe.video_link}?showinfo=0&rel=0`"
            allowfullscreen
            frameborder="0"
            height="280"
            width="452"
          ></iframe>
        </div>

        <div class="nutrition-header-block-mobile__content">
          <div class="nutrition-header-block-mobile__title">
            Ингредиенты:
          </div>

          <div class="nutrition-header-block-view-item-section">
            <span
              class="nutrition-text--header-block-view-item-section-text nutrition-header-block-view-item-section--item"
              v-for="(ingredient, key) in recipe.ingredients"
              :key="`recipe-ingredients-modal-${key}`"
            >
              {{ ingredient.name }} -
              {{
                !ingredient.weight
                  ? `${ingredient.unit}`
                  : `${ingredient.weight} ${ingredient.unit}`
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="yf-container">
      <div class="nutrition-info-wrap text-center">
        <div class="nutrition-recipe-info">
          <div class="nutrition-recipe-info__item">
            <span class="yf-bju-icon"></span>
            <div class="nutrition-recipe-info__content">
              <div class="nutrition-recipe-info__title">КБЖУ</div>
              <div class="nutrition-recipe-info__value">
                {{ recipe.kkal }}/{{ recipe.protein }}/{{ recipe.fat }}/{{
                  recipe.carbon
                }}
              </div>
            </div>
          </div>

          <div class="nutrition-recipe-info__item">
            <span class="yf-time-icon"></span>
            <div class="nutrition-recipe-info__content">
              <div class="nutrition-recipe-info__title">Время</div>
              <div class="nutrition-recipe-info__value">
                {{ recipe.cook_time }} {{ declOfNum(recipe.cook_time, ['минута', 'минуты', 'минут']) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="videos-recipe-wr">
      <div class="recipe-content" id="video_steps" v-if="recipe.steps">
        <h2 class="h2 nutrition-h2 text-center">Пошаговый рецепт</h2>
        <div class="recipe-content__description">
          <div
            class="recipe-content__description--step"
            v-for="(step, key) in recipe.steps"
            :key="`nutrition-steps-item-${key}`"
          >
            <div class="index show-on show-on--mobile">
              Шаг {{ step.step_order }} из
              {{ Object.keys(recipe.steps).length }}
            </div>

            <div v-if="step.step_image">
              <div class="gcore-image-slider">
                <img :src="`${$APP_URL}${step.step_image}`" alt="" />
              </div>
            </div>
            <div v-else-if="step.gcore_image_link">
              <div class="gcore-image-slider">
                <img :src="`${step.gcore_image_link}`" alt="" />
              </div>
            </div>
            <div class="iframe-slider-wr" v-else>
              <iframe
                class="iframe-slider"
                :src="
                  `${step.video_link}?showinfo=0&loop=1&playlist=${step.videoId}`
                "
                allowfullscreen
                frameborder="0"
              ></iframe>
            </div>

            <div class="columne-divider--35 hide-on--tablet"></div>

            <div class="description">
              <div class="index hide-on--tablet">
                Шаг {{ step.step_order }} из
                {{ Object.keys(recipe.steps).length }}
              </div>
              <div class="text">
                {{ step.description }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="yandex-share-links text-center">
        <p>Поделиться рецептом</p>
        <yandex-share
          :services="[
            'vkontakte',
            'facebook',
            'twitter',
            'telegram',
            'odnoklassniki',
            'viber',
            'whatsapp'
          ]"
        />
      </div>
    </div>

    <div
      v-if="recipe"
      class="fs-ptiles-tape fs-ptiles-tape--narrow"
      style="margin:auto;"
    >
      <h2 class="h2 nutrition-h2 text-center">Отзывы</h2>

      <FSComment :commentObject="recipe" :type="type"></FSComment>
      <FSComments :commentObject="recipe" :type="type"></FSComments>
    </div>

    <div class="yf-container other-recipe-container">
      <h2 class="h2 nutrition-h2">Другие рецепты</h2>
      <div class="other-recipe-wr">
        <div
          class="other-recipe"
          v-for="(recipeItem, key) of recipe.recipes"
          :key="`other-recipe-${key}`"
        >
          <router-link
            :to="`/recipes/${recipeItem.id}`"
            class="other-recipe--link"
          >
            <picture>
              <source :srcset="`${recipeItem.image.paths.webp}`" type="image/webp">
              <source :srcset="`${recipeItem.image.paths.jpg}`" type="image/jpeg"> 
              <img class="other-recipe--image" :src="`${recipeItem.image.paths.jpg}`" />
            </picture>
            <div class="other-recipe--info">
              <!-- <p class="other-recipe--type">
                {{ recipeItem.type.name }}
              </p> -->
              <h4 class="other-recipe--info-title">
                {{ recipeItem.name }}
              </h4>
              <div class="other-recipe--info-data">
                <div class="kkal">{{ recipeItem.kkal }} Ккал</div>
                <div class="cook-time">
                  <img
                    class="disctop-icon"
                    src="@/assets/img/recipe/new/cock-time.svg"
                    alt=""
                  />
                  <img
                    class="mobile-icon"
                    src="@/assets/img/icon-clock.png"
                    alt=""
                  />
                  {{ recipeItem.cook_time }}
                </div>
                <div class="rating">
                  <img
                    class="disctop-icon"
                    src="@/assets/img/recipe/new/star.svg"
                    alt=""
                  />
                  <img
                    class="mobile-icon"
                    src="@/assets/img/icon-star.png"
                    alt=""
                  />
                  <template v-if="recipeItem.rating">{{ Number(recipeItem.rating).toFixed(1) }}</template>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import YandexShare from "@cookieseater/vue-yandex-share";

import FSComment from "@/components/PagePartials/comments/item.vue";
import FSComments from "@/components/PagePartials/comments/index.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_RECIPE_ITEM } from "@/core/services/store/recipe.module";

export default {
  name: "PageNutritionView",
  components: {
    FSComment,
    FSComments,
    YandexShare
  },
  data() {
    return {
      type: 2,
      currentRecipeId: this.$route.params.id,
      dishesList: {
        1: "Завтрак",
        2: "Основное",
        3: "Гарнир",
        4: "Салат"
      }
    };
  },
  computed: {
    isRecipeLoaded() {
      return this.$store.getters.recipeItemIsLoaded;
    },
    recipe() {
      const recipe = this.$store.getters.recipeItem;

      if (recipe.steps !== undefined) {
        $.each(recipe.steps, (key, item) => {
          const link = item.video_link;
          const videoId = link.substring(30, link.length);

          recipe.steps[key].videoId = videoId;
        });
      }

      return recipe;
    }
  },
  created() {
    this.getRecipe(this.currentRecipeId);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Просмотр рецепта" }]);

    $(".recipe-tooltip").tooltip({
      placement: "bottom"
    });
  },
  methods: {
    printWindow() {
      window.print();
    },
    getRecipe(recipeId) {
      this.$store.dispatch(GET_RECIPE_ITEM, recipeId);
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];

      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }
  },
  watch: {
    "$route.params.id": function setRouteParamId(id) {
      this.getRecipe(id);
    }
  }
};
</script>
