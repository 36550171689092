var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.course && _vm.indexCategory >= 0 && _vm.indexCourse >= 0)?_c('div',[(
      (_vm.course.users_packs && _vm.course.users_packs.length > 0) ||
        _vm.course.special_course == true
    )?_c('div',[_c('div',{staticClass:"courses-list__item--img"},[_c('div',{staticClass:"raiting"},[_c('img',{staticClass:"star",attrs:{"src":require("@/assets/img/training/star.svg")}}),_vm._v(" "+_vm._s(_vm.course.rating.toFixed(1))+" ")]),_c('picture',[_c('source',{attrs:{"srcset":`${_vm.course.additional_avatar.paths?.webp}`,"type":"image/webp"}}),_c('source',{attrs:{"srcset":`${_vm.course.additional_avatar.paths?.jpg}`,"type":"image/jpeg"}}),(_vm.course.additional_avatar)?_c('img',{attrs:{"src":`${_vm.course.additional_avatar.paths?.jpg}`,"alt":"courses-pic"}}):_vm._e()])]),_c('div',{staticClass:"courses-list__item--data"},[_c('div',{staticClass:"trainer"},[_c('img',{attrs:{"src":`${_vm.course.authors[0].avatar_image}`,"alt":""}}),_c('h3',[(
              _vm.course.authors && _vm.course.authors[0] && _vm.course.authors[0].name
            )?_c('span',[_vm._v(" "+_vm._s(_vm.course.authors[0].name)+" ")]):_vm._e(),(
              _vm.course.authors && _vm.course.authors[0] && _vm.course.authors[0].surname
            )?_c('span',[_vm._v(" "+_vm._s(_vm.course.authors[0].surname)+" ")]):_vm._e()])]),_c('div',{staticClass:"statistics"},[_c('p',{staticClass:"d-flex"},[_c('img',{staticClass:"star",attrs:{"src":require("@/assets/img/icons-svg/chronometer.svg")}}),_vm._v(" "+_vm._s(_vm.course.trainings_count)+" ")]),_c('p',{staticClass:"d-flex",staticStyle:{"margin-right":"8px"}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_vm._v(" "+_vm._s(`${parseInt(_vm.course.duration / 3600)} ч. ${parseInt( ((_vm.course.duration - (_vm.course.duration % 60)) / 60) % 60 )} мин.`)+" ")]),_c('p',{staticClass:"d-flex"},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_vm._v(" "+_vm._s(_vm.course.users_count)+" ")])]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"description-text",staticStyle:{"max-height":"127px","overflow":"hidden"}},[_c('p',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(_vm.course.additional_description)}})])]),_c('div',{staticClass:"buy-link"},[_vm._v("Подробнее")])])]):_c('div',[_c('div',{staticClass:"courses-list__item--img"},[_c('div',{staticClass:"raiting"},[_c('img',{staticClass:"star",attrs:{"src":require("@/assets/img/training/star.svg")}}),_vm._v(" "+_vm._s(_vm.course.rating.toFixed(1))+" ")]),_c('picture',[_c('source',{attrs:{"srcset":`${_vm.course.additional_avatar.paths?.webp}`,"type":"image/webp"}}),_c('source',{attrs:{"srcset":`${_vm.course.additional_avatar.paths?.jpg}`,"type":"image/jpeg"}}),_c('img',{attrs:{"src":`${_vm.course.additional_avatar.paths?.jpg}`,"alt":"courses-pic"}})])]),_c('div',{staticClass:"courses-list__item--data"},[_c('div',{staticClass:"trainer"},[_c('img',{attrs:{"src":`${_vm.course.authors[0].avatar_image}`,"alt":""}}),_c('h3',[(
              _vm.course.authors && _vm.course.authors[0] && _vm.course.authors[0].name
            )?_c('span',[_vm._v(" "+_vm._s(_vm.course.authors[0].name)+" ")]):_vm._e(),(
              _vm.course.authors && _vm.course.authors[0] && _vm.course.authors[0].surname
            )?_c('span',[_vm._v(" "+_vm._s(_vm.course.authors[0].surname)+" ")]):_vm._e()])]),_c('div',{staticClass:"statistics"},[_c('p',{staticClass:"d-flex"},[_c('img',{staticClass:"star",staticStyle:{"max-width":"17px"},attrs:{"src":require("@/assets/img/icons-svg/chronometer.svg")}}),_vm._v(" "+_vm._s(_vm.course.trainings_count)+" ")]),_c('p',{staticClass:"d-flex",staticStyle:{"margin-right":"8px"}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_vm._v(" "+_vm._s(`${parseInt(_vm.course.duration / 3600)} ч. ${parseInt( ((_vm.course.duration - (_vm.course.duration % 60)) / 60) % 60 )} мин.`)+" ")]),_c('p',{staticClass:"d-flex"},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_vm._v(" "+_vm._s(_vm.course.users_count)+" ")])]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"description-text",staticStyle:{"max-height":"127px","overflow":"hidden"}},[_c('p',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(_vm.course.additional_description)}})])]),_c('div',{staticClass:"buy-link"},[_vm._v("Купить")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }