<template>
  <div
    class="training-mobile"
    :class="{
      'no-subscription': !userSubscriptions && program.tariff == null
    }"
    style="width: 100%"
  >
    <div class="training-sidebar__title">План тренировок</div>
    <div id="jsid-scrollbar-mobile" class="scrollbar">
      <div class="handle">
        <div class="mousearea"></div>
      </div>
    </div>
    <div id="frame-mobile" class="frame">
      <ul class="slidee">
        <li
          class="training-slider-block"
          v-for="(trainingItem, indexTraining) of trainings"
          :key="`training-${indexTraining}-${trainingItem.id}`"
        >
          <div class="d-flex">
            <div
              v-if="!trainingItem.isRestDay"
              class="training-list__item"
              :class="{
                'training-list__item-done': trainingItem.date_last_done,
                'training-list__item-next': trainingItem.is_next_planed === true
              }"
              @click="switchTraining(trainingItem, indexTraining)"
            >
              <div class="training-list__item--img">
                <img
                  v-if="trainingItem.poster_thumb"
                  :src="trainingItem.poster_thumb"
                />
                <img
                  v-else-if="trainingItem.poster"
                  :src="trainingItem.poster"
                />
                <img
                  v-else
                  :src="trainingItem.video_local_url"
                />
                <div class="training-list__item--duration">
                  {{
                    `${parseInt(trainingItem.duration / 60)}:${
                      trainingItem.duration % 60 >= 10
                        ? trainingItem.duration % 60
                        : trainingItem.duration % 60 > 0
                        ? `0${trainingItem.duration % 60}`
                        : "00"
                    }`
                  }}
                </div>
              </div>
              <div class="training-list__item--info-wr">
                <div class="training-list__item--title-wr">
                  <div class="training-list__item--title">
                    <div style="display: inline-block;padding-right: 8px">
                      {{ trainingItem.training_name }}
                    </div>
                    <div style="display: inline-block;">
                      <span
                        :class="
                          `complexity-level complexity-level--${Math.ceil(
                            trainingItem.training_level / 3
                          )}`
                        "
                      ></span>
                      <span class="calories-done">
                        {{
                          `${parseInt(
                            (trainingItem.duration / 60) *
                              trainingItem.calories_coefficient
                          )} Ккал`
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="training-list__item--tags-wr">
                  <span
                    v-if="
                      trainingItem.training_type &&
                        trainingItem.training_type.name
                    "
                    class="training-list__item--tag first"
                  >
                    {{ trainingItem.training_type.name }}
                  </span>
                  :
                  <span class="training-list__item--tag">
                    <span
                      v-for="(part, indexPart) of trainingItem.parts"
                      :key="
                        `part-${trainingItem.id}-${trainingItem.program_id}-${part.id}`
                      "
                    >
                      {{
                        part.name +
                          (indexPart + 1 === trainingItem.parts.length
                            ? ""
                            : ", ")
                      }}
                    </span>
                  </span>
                </div>
                <div
                  class="training-list__item-data"
                  :class="{
                    'training-list__item-data--done':
                      trainingItem.date_last_done,
                    'training-list__item-data--next-day':
                      trainingItem.is_next_planed == true
                  }"
                >
                  <span v-if="trainingItem.date_last_done">
                    <img
                      style="margin-right: 5px"
                      src="@/assets/img/training/check.svg"
                    />
                    {{
                      `Выполнена: ${getFormattedDate(
                        trainingItem.date_last_done,
                        "DD.MM"
                      )}`
                    }}
                  </span>
                  <span
                    v-else-if="
                      trainingItem.is_next_planed &&
                        trainingItem.is_next_planed == true &&
                        trainingItem.date_planned === tomorrow()
                    "
                  >
                    Завтра
                  </span>
                  <span
                    v-else-if="
                      trainingItem.is_next_planed &&
                        trainingItem.is_next_planed == true &&
                        trainingItem.date_planned === today()
                    "
                  >
                    Сегодня
                  </span>
                  <span
                    v-else-if="
                      trainingItem.date_planned &&
                        trainingItem.date_planned !== false
                    "
                    style="text-transform: capitalize;"
                  >
                    {{
                      `${getFormattedDate(
                        trainingItem.date_planned,
                        "ddd., DD.MM"
                      )}`
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="training-list__item training-list__item-rest">
              <div class="training-list__item--img">
                <img src="@/assets/img/training/relax.jpeg" />
              </div>
              <div class="training-list__item--info-wr">
                <div class="training-list__item--title-wr">
                  <div class="training-list__item--title">День отдыха!</div>
                </div>
                <div class="training-list__item--tags-wr">
                  <span class="training-list__item--tag first">
                    Расслабоение </span
                  >:
                  <span class="training-list__item--tag">
                    на все тело 😉
                  </span>
                </div>
                <div
                  class="training-list__item-data"
                  style="text-transform: capitalize"
                >
                  {{ getTrainingRelaxDate(trainingItem) }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import moment from "moment";
import { getFormattedDate } from "@/assets/js/helpers";

export default {
  name: "FSTrainingTrainingsMobile",
  props: {
    trainings: Array,
    training: Object,
    program: Object
  },
  data() {
    return {
      slyElementMobile: null
    };
  },
  computed: {
    ...mapGetters(["userSubscriptions"]),
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    }
  },
  beforeDestroy() {
    this.$root.$off("page_training__start_program");
  },
  mounted() {
    let __self__ = this;

    this.setTrainingList();

    this.$root.$on("page_training__start_program", function() {
      __self__.slyElementMobile.sly("slideTo", 0);
    });
  },
  destroyed() {
    if (this.slyElementMobile && this.slyElementMobile != null) {
      this.slyElementMobile.sly("destroy");
    }
  },
  methods: {
    getFormattedDate,
    getTrainingRelaxDate(training) {
      let formatedDate = "";
      let date = null;

      if (training.date_last_done) {
        date = training.date_last_done;
      } else if (training.date_planned) {
        date = training.date_planned;
      } else {
        date = new Date();
      }

      formatedDate = moment(date).format("ddd., DD.MM");

      return formatedDate;
    },
    switchTraining(training, index) {
      if (training.id != this.training.id) {
        this.$router.push({
          name: "training-pack-pack-training",
          params: {
            pack: this.program.id,
            training: training.id
          }
        });
      }

      this.slyElementMobile.sly("toStart", index);
    },
    tomorrow() {
      const today = new Date();
      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

      return moment(tomorrow).format("YYYY-MM-DD");
    },
    today() {
      const today = new Date();

      return moment(today).format("YYYY-MM-DD");
    },
    setTrainingList() {
      let __self__ = this;
      let startAtPosition = 0;
      let elementScrollBar = $(`#jsid-scrollbar-mobile`);
      let elementSly = $(`#frame-mobile`);

      if (!this.trainings || elementSly.length < 1) {
        return;
      }

      this.trainings.forEach(function(training, indexTraining) {
        const index = indexTraining < 1 ? 0 : indexTraining;

        if (
          __self__.$route.params.training &&
          __self__.$route.params.training == training.id
        ) {
          startAtPosition = index;
        } else if (
          training.is_next_planed &&
          !__self__.$route.params.training
        ) {
          startAtPosition = index;
        }
      });

      setTimeout(() => {
        let options = {
          horizontal: true,
          itemNav: "basic",
          easing: "swing",
          scrollBar: elementScrollBar,
          dragHandle: true,
          speed: 300,
          startAt: 0,
          scrollBy: 1,
          scrollTrap: true,
          mouseDragging: 1,
          touchDragging: 1
        };

        __self__.slyElementMobile = elementSly.sly(options).init();
        __self__.slyElementMobile.sly("reload");
        __self__.slyElementMobile.sly("toStart", startAtPosition);
      }, 1000);
    }
  }
};
</script>
