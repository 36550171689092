import commentsService from "../../services/comments.service";

// action types
export const ADD_COMMENT = "addComment";
export const GET_COMMENTS_LIST = "getCommentsList";
export const TOGGLE_LIKE_COMMENT = "toggleLikeComment";

// mutator types
export const UPDATE_COMMENT = "updateComment";
export const COMMENT_IS_ADDING = "isCommentsListLoaded";
export const COMMENT_IS_ADDED = "isCommentsListLoaded";
export const COMMENTS_LIST_IS_LOADED = "isCommentsListLoaded";
export const COMMENTS_LIST_IS_LOADING = "isCommentsListLoading";
export const SET_COMMENT_JUST_ADDED = "setCommentJustAdded";

export default {
  state: {
    comment: {
      isAdded: false,
      isAdding: false
    },
    comments: {
      list: [],
      justAdded: null,
      meta: null,
      isLoaded: false,
      isLoading: false
    }
  },
  getters: {
    commentsList: state => state.comments.list,
    commentsMeta: state => state.comments.meta,
    commentsIsLoading: state => state.comments.isLoading,
    commentsIsLoaded: state => state.comments.isLoaded
  },
  actions: {
    [GET_COMMENTS_LIST]({ commit }, request) {
      commit(COMMENTS_LIST_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        commentsService.getComments(
          request,
          response => {
            commit(GET_COMMENTS_LIST, response.data);

            commit(COMMENTS_LIST_IS_LOADED, true);
            commit(COMMENTS_LIST_IS_LOADING, false);

            resolve(response);
          },
          error => {
            commit(COMMENTS_LIST_IS_LOADED, false);
            commit(COMMENTS_LIST_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    [ADD_COMMENT]({ commit }, request) {
      commit(COMMENT_IS_ADDED, false);
      commit(COMMENT_IS_ADDING, true);

      return new Promise((resolve, reject) => {
        commentsService.addComment(
          request,
          data => {
            commit(UPDATE_COMMENT, data.data);

            if (!request.parent) {
              commit(SET_COMMENT_JUST_ADDED, data.data);
            }

            commit(COMMENT_IS_ADDED, true);
            commit(COMMENT_IS_ADDING, false);

            resolve(data);
          },
          error => {
            commit(COMMENT_IS_ADDING, false);
            commit(COMMENT_IS_ADDED, false);

            reject(error);
          }
        );
      });
    },
    [TOGGLE_LIKE_COMMENT]({ commit }, comment) {
      commit(TOGGLE_LIKE_COMMENT, comment);

      return new Promise((resolve, reject) => {
        commentsService.toggleLikeComment(
          comment.id,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  mutations: {
    [GET_COMMENTS_LIST](state, comments) {
      if (comments.current_page == 1) {
        state.comments.list = comments.data;

        if (state.comments.justAdded) {
          const indexComment = state.comments.list.findIndex(el => el.id == state.comments.justAdded.id);

          state.comments.list.splice(indexComment, 1);

          state.comments.list.unshift(state.comments.justAdded);

          state.comments.justAdded = null;
        }
      } else {
        state.comments.list.push(...comments.data);
      }

      state.comments.meta = comments;
    },
    [UPDATE_COMMENT](state, comment) {
      if (comment) {
        const indexComment = state.comments.list.findIndex(el => el.id == comment.id);

        state.comments.list.splice(indexComment, 1, comment);
      }
    },
    [COMMENTS_LIST_IS_LOADING](state, isLoading) {
      state.comments.isLoading = isLoading;
    },
    [COMMENTS_LIST_IS_LOADED](state, isLoaded) {
      state.comments.isLoaded = isLoaded;
    },
    [COMMENT_IS_ADDED](state, isAdded) {
      state.comment.isAdded = isAdded;
    },
    [COMMENT_IS_ADDING](state, isAdding) {
      state.comment.isAdding = isAdding;
    },
    [SET_COMMENT_JUST_ADDED](state, comment) {
      state.comments.justAdded = comment;
    },
    [TOGGLE_LIKE_COMMENT](state, comment) {
      function toggleLikeComment(array) {
        let commentIndex = array.findIndex(el => el.id == comment.id);

        if (array[commentIndex].is_liked) {
          array[commentIndex].is_liked = 0;
          array[commentIndex].likes--;
        } else {
          array[commentIndex].is_liked = 1;
          array[commentIndex].likes++;
        }
      }

      if (comment.parent_comment_id) {
        let commentParent = state.comments.list.find(el => el.id == comment.parent_comment_id);

        toggleLikeComment(commentParent.reply);
      } else {
        toggleLikeComment(state.comments.list);
      }
    }
  }
};
