<template>
  <div>
    <NoticeSubscriptionRenewal v-if="subscriptionsRequestCompleted && !userSubscriptions && program && !program.is_trial || !userHasAccess"/>
    <div
      v-else-if="program && program.hide_for_user === true && program.days_after_create > 1"
      class="fs-ptile--programs-my fs-ptile--wrap"
    >
      <div>
        <h5 class="fs-ptile-title d-flex mb-8 mt-8 mr-8 ml-8" style="max-width: 100%">
          <img src="@/assets/img/icon-attention-grey.svg" class="mr-4" />
          Программа станет доступна {{ programActiveAt(program) }}
        </h5>
      </div>
    </div>
    <template v-else>
      <FSTrainingProgram
        ref="training-block-program"
        v-if="program && trainer"
        :program="program"
        :trainer="trainer"
        :training="training"
      ></FSTrainingProgram>
      <div class="d-flex flex-wrap">
        <div class="asdad">
          <div
            ref="training-block-training"
            class="fs-ptiles-tape fs-ptiles-tape--narrow"
            style="float: left; width: 100%;"
          >
            <div class="fs-ptile">
              <div style="width: 100%">
                <FSTrainingTraining
                  v-if="training && program && trainer && trainings"
                  :training="training"
                  :program="program"
                  :trainer="trainer"
                  :trainings="trainings"
                ></FSTrainingTraining>
              </div>
            </div>

            <hide-at ref="training-block-trainings-hide-at" breakpoint="large">
              <div class="fs-ptile">
                <FSTrainingTrainingsMobile
                  v-if="trainings && program && training"
                  :trainings="trainings"
                  :training="training"
                  :program="program"
                ></FSTrainingTrainingsMobile>
              </div>
            </hide-at>

            <!-- <FSTrainingComment
              v-if="training"
              :training="training"
            ></FSTrainingComment> -->
            <FSComment
              v-if="training"
              :commentObject="training"
              :type="type"
            ></FSComment>
          </div>

          <show-at
            ref="training-block-trainings-show-at"
            breakpoint="large"
            v-b-visible="handleVisible"
          >
            <FSTrainingTrainings
              v-if="trainings && program && training && user.id"
              :trainings="trainings"
              :training="training"
              :program="program"
            ></FSTrainingTrainings>
          </show-at>
        </div>

        <div style="width: 100%">
          <div class="fs-ptiles-tape fs-ptiles-tape--narrow">
            <FSComments
              v-if="training"
              :commentObject="training"
              :type="type"
            ></FSComments>
            <!-- <FSTrainingComments
              v-if="training"
              :training="training"
            ></FSTrainingComments> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from "jquery";
import "bootstrap";
import "bootstrap-select";
import moment from "moment";
import { showAt, hideAt } from "vue-breakpoints";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import FSComment from "@/components/PagePartials/comments/item.vue";
import FSComments from "@/components/PagePartials/comments/index.vue";
import FSTrainingProgram from "@/components/PagePartials/trainings/Program.vue";
import FSTrainingTraining from "@/components/PagePartials/trainings/Item.vue";
import FSTrainingTrainings from "@/components/PagePartials/trainings/index.vue";
import FSTrainingTrainingsMobile from "@/components/PagePartials/trainings/Mobile.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "PageTraining",
  components: {
    FSTrainingTrainingsMobile,
    FSComment,
    FSComments,
    FSTrainingProgram,
    FSTrainingTraining,
    FSTrainingTrainings,
    hideAt,
    showAt,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      type: 1,
      currentPack: this.$route.params.pack,
      currentTrainingId: this.$route.params.training
    };
  },
  computed: {
    program() {
      return this.$store.getters.trainingProgram;
    },
    trainer() {
      return this.$store.getters.trainingTrainer;
    },
    training() {
      return this.$store.getters.trainingTraining;
    },
    trainings() {
      return this.$store.getters.trainingPlan;
    },
    user() {
      return this.$store.getters.userData;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
    subscriptionsRequestCompleted() {
      return this.$store.getters.subscriptionsRequestCompleted;
    },
    userHasAccess() {
      if (this.training && this.training.is_course && !this.user.allowed_special_course && this.user.id) {
        return false;
      }
      return true;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },
  mounted() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");

    this.setBreadcrumb();
    this.getTraining();
    this.$store.dispatch("getUserSubscriptions");
  },
  methods: {
    handleVisible(isVisible) {
      if (isVisible === true) {
        this.trainingIsLoaded();
      }
    },
    trainingIsLoaded() {
      const height = this.$refs["training-block-training"].clientHeight;

      this.$root.$emit("event--training-is-loaded", height);
    },
    clickOn() {
      this.modalBuyTraining = $("[data-remodal-id='buy-training']").remodal({
        hashTracking: false
      });
    },
    _openBuyTraining() {
      this.$nextTick(() => {
        this.modalBuyTraining.open();
      });
    },
    getTraining() {
      this.$store.dispatch("getTraining", {
        id: this.currentTrainingId,
        pack: this.currentPack,
        initLoading: true
      })
        .finally(() => this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading"))
    },
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Программы", route: "/programs" },
        {
          title: `     ${
            this.program && this.program.name
              ? ' "' + this.program.name + '"'
              : ""
          }`
        }
      ]);
    },
    programActiveAt(program) {
      let date = moment(program.created_at);

      date.add(program.days_after_create, "days");

      return date.format("Y.MM.DD");
    }
  },
  destroyed() {
    this.$store.dispatch("clearTraining");
  },
  watch: {
    program() {
      this.setBreadcrumb();
    },
    $route(to, from) {
      let heightElementCarrotquestBlock = $(
        "#yf-carrotquest-notification-wrapper"
      ).height();
      let heightElementProgramBlock = this.$refs["training-block-program"].$el
        .clientHeight;
      let heightElementTrainingsHideAtBlock = this.$refs[
        "training-block-trainings-hide-at"
      ].$el.clientHeight;

      if (this.program.id != to.params.pack) {
        this.getTraining();
      }

      if ((this.training.id != to.params.training) && !from.query.token) {
        this.$store.dispatch("switchTraining", {
          id: to.params.training
        });
      }

      if (
        to.name.indexOf("training-pack") >= 0 &&
        from.name &&
        from.name.indexOf("training-pack") >= 0
      ) {
        window.scrollTo({
          top:
            heightElementCarrotquestBlock +
            heightElementProgramBlock +
            (heightElementTrainingsHideAtBlock ? 93 : 63),
          behavior: "smooth"
        });
      }
    }
  }
};
</script>

<style>
  @media screen and (min-width: 1000px) {
    .training-modal__conteiner {
      max-height: 90vh;
    }
  }

  #yf-carrotquest-notification-wrapper {
    padding: 0 32px;
    /* margin-bottom: -36px; */
  }
  @media screen and (max-width: 991px) {
    #yf-carrotquest-notification-wrapper {
      padding: 0 12px;
    }
  }
</style>