<template>
  <div v-if="course && indexCategory >= 0 && indexCourse >= 0">
    <div
      v-if="
        (course.users_packs && course.users_packs.length > 0) ||
          course.special_course == true
      "
    >
      <div class="courses-list__item--img">
        <div class="raiting">
          <img class="star" src="@/assets/img/training/star.svg" />
          {{ course.rating.toFixed(1) }}
        </div>
        <picture>
          <source :srcset="`${course.additional_avatar.paths?.webp}`" type="image/webp">
          <source :srcset="`${course.additional_avatar.paths?.jpg}`" type="image/jpeg"> 
          <img v-if="course.additional_avatar" :src="`${course.additional_avatar.paths?.jpg}`" alt="courses-pic" />
        </picture>
      </div>
      <div class="courses-list__item--data">
        <div class="trainer">
          <img :src="`${course.authors[0].avatar_image}`" alt="" />
          <h3>
            <span
              v-if="
                course.authors && course.authors[0] && course.authors[0].name
              "
            >
              {{ course.authors[0].name }}
            </span>
            <span
              v-if="
                course.authors && course.authors[0] && course.authors[0].surname
              "
            >
              {{ course.authors[0].surname }}
            </span>
          </h3>
        </div>
        <div class="statistics">
          <p class="d-flex">
            <img class="star" src="@/assets/img/icons-svg/chronometer.svg" />
            {{ course.trainings_count }}
          </p>
          <p class="d-flex" style="margin-right: 8px">
            <i class="menu-icon flaticon2-chronometer"></i>
            {{
              `${parseInt(course.duration / 3600)} ч. ${parseInt(
                ((course.duration - (course.duration % 60)) / 60) % 60
              )} мин.`
            }}
          </p>
          <p class="d-flex">
            <i class="menu-icon flaticon2-user"></i>
            {{ course.users_count }}
          </p>
        </div>
        <div class="description">
          <div
            class="description-text"
            style="max-height: 127px;overflow: hidden;"
          >
            <p
              style="display: inline-block;"
              v-html="course.additional_description"
            ></p>
          </div>
        </div>
        <div class="buy-link">Подробнее</div>
      </div>
    </div>
    <div v-else>
      <!-- <div
        v-if="!course.users_packs || course.users_packs.length < 1"
        class="no-subscription"
        style="overflow: hidden; border-radius: 20px;"
      > -->
      <div class="courses-list__item--img">
        <div class="raiting">
          <img class="star" src="@/assets/img/training/star.svg" />
          {{ course.rating.toFixed(1) }}
        </div>
        <picture>
          <source :srcset="`${course.additional_avatar.paths?.webp}`" type="image/webp">
          <source :srcset="`${course.additional_avatar.paths?.jpg}`" type="image/jpeg"> 
          <img :src="`${course.additional_avatar.paths?.jpg}`" alt="courses-pic" />
        </picture>
      </div>
      <div class="courses-list__item--data">
        <div class="trainer">
          <img :src="`${course.authors[0].avatar_image}`" alt="" />
          <h3>
            <span
              v-if="
                course.authors && course.authors[0] && course.authors[0].name
              "
            >
              {{ course.authors[0].name }}
            </span>
            <span
              v-if="
                course.authors && course.authors[0] && course.authors[0].surname
              "
            >
              {{ course.authors[0].surname }}
            </span>
          </h3>
        </div>
        <div class="statistics">
          <p class="d-flex">
            <img
              style="max-width:17px"
              class="star"
              src="@/assets/img/icons-svg/chronometer.svg"
            />
            {{ course.trainings_count }}
          </p>
          <p class="d-flex" style="margin-right: 8px">
            <i class="menu-icon flaticon2-chronometer"></i>
            {{
              `${parseInt(course.duration / 3600)} ч. ${parseInt(
                ((course.duration - (course.duration % 60)) / 60) % 60
              )} мин.`
            }}
          </p>
          <p class="d-flex">
            <i class="menu-icon flaticon2-user"></i>
            {{ course.users_count }}
          </p>
        </div>
        <div class="description">
          <div
            class="description-text"
            style="max-height: 127px;overflow: hidden;"
          >
            <p
              style="display: inline-block;"
              v-html="course.additional_description"
            ></p>
          </div>
        </div>
        <div class="buy-link">Купить</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FSCourseItem",
  props: {
    course: Object,
    indexCourse: Number,
    indexCategory: Number
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
