<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img alt="Logo" src="/media/logos/logo-star.svg" class="mobile-logo" />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin: Calendar -->
      <div id="calendar-icon-mob" class="calendar-icon-mob mr-2">
        <i class="flaticon2-calendar"></i>
      </div>
      <!--begin: Calendar -->
      <div class="hide-on hide-on--tablett">
        <router-link to="/new-profile" class="menu-link mr-2">
          <i class="flaticon2-gear"></i>
        </router-link>
      </div>

      <!--begin::Aside Mobile Toggle-->
      <!--<button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>-->
      <!--end::Aside Mobile Toggle-->
      <router-link :to="`/fitness-profile/${userData.url_slug}`">
        <v-avatar v-if="userData.avatar">
          <img :src="avatar" class="avatare" alt="avatar" />
        </v-avatar>
        <span
          v-if="!userData.avatar && userData.full_name"
          class="symbol symbol-35 symbol-light-success"
        >
          <span class="symbol-label font-size-h5 font-weight-bold">
            {{ userData.full_name.charAt(0).toUpperCase() }}
          </span>
        </span>
      </router-link>
      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <!-- <router-link :to="`/fitness-profile/${userData.url_slug}`">
        <button
          class="btn btn-hover-text-primary p-0 ml-2"
          ref="kt_header_mobile_topbar_toggle"
        >
          <span class="svg-icon svg-icon-xl"> -->
      <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
      <!-- <inline-svg
              class="svg-icon"
              src="../../media/svg/icons/General/User.svg"
            /> -->
      <!--end::Svg Icon-->
      <!-- </span>
        </button>
      </router-link> -->
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
// import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

import defaultPhoto from "@/assets/img/noimage.png";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    // KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
    $(".calendar-icon-mob").click(function() {
      $(".calendar-box ").toggleClass("show-calendar");
    });
    $(".calendar-icon-mob").click(function() {
      $(".training-date").toggleClass("show");
    });
  },
  computed: {
    ...mapGetters(["userData", "currentUserPersonalInfo"]),
    ...mapGetters(["layoutConfig", "getClasses"]),
    avatar() {
      return this.userData.avatar
        ? `${this.userData.avatar.path}`
        : defaultPhoto;
    },
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },
  watch: {
    userData() {
      if (this.userData.name) {
        this.userName = `${this.userData.name} (${this.userData.email})`;
        this.userNameFirstLatter = this.userData.name.charAt(0).toUpperCase();
      } else {
        this.userName = this.userData.email;
        this.userNameFirstLatter = this.userData.email.charAt(0).toUpperCase();
      }
    }
  }
};
</script>

<style>
  .mobile-logo {
    max-width: 41px;
  }
</style>
