<template>
  <div
    v-if="program && indexCategory >= 0 && indexProgram >= 0"
    class="catalog--item__link"
  >
    <div class="catalog-item-wrapper">
      <div
        v-if="
          (!userSubscriptions && !isTrial &&
            (!program || (program && program.tariff == null))) && !program.is_trial ||
            (program.hide_for_user && program.hide_for_user == true)
        "
        class="catalog--item__image catalog--item__image--program no-subscription"
      >
        <div class="no-subscription-bg"></div>
        <div class="no-subscription-wr">
          <img
            class="no-subscription-img"
            src="@/assets/img/icons-svg/lock-programs.svg"
            alt=""
          />
          <p
            v-if="program.days_after_create && program.days_after_create > 0 && userSubscriptions"
            class="no-subscription-text"
          >
            Станет доступна {{ programActiveAt(program) }}
          </p>
          <p
            v-else
            class="no-subscription-text"
          >
            Программа недоступна
          </p>
        </div>
        <picture>
          <source :srcset="`${program.pics.webp}`" type="image/webp">
          <source :srcset="`${program.pics.jpg}`" type="image/jpeg"> 
          <img class="img" :src="`${program.pics.jpg}`" />
        </picture>
      </div>

      <div v-else class="catalog--item__image catalog--item__image--program">
        <picture>
          <source :srcset="`${program.pics.webp}`" type="image/webp">
          <source :srcset="`${program.pics.jpg}`" type="image/jpeg"> 
          <img class="img" :src="`${program.pics.jpg}`" />
        </picture>
        <span class="new_flag new_flag--hit" :class="`type_${program.is_hit}`">
          HIT
        </span>
        <span class="new_flag" :class="`type_${program.new_flag}`">
          NEW
        </span>
      </div>

      <div class="catalog--item__description">
        <p class="catalog--item__description--name">
          <!-- {{ program.name }} -->
          <span
            v-if="
              program.authors && program.authors[0] && program.authors[0].name && program.trainer_name
            "
          >
            {{ program.authors[0].name }}
          </span>
          <span
            v-if="
              program.authors &&
                program.authors[0] &&
                program.authors[0].surname
            "
          >
            {{ program.authors[0].surname }}
          </span>
          <span v-else>
            {{ program.trainer_name }}
          </span>
        </p>

        <!-- <div class="trainer-name">
          <p>{{ program.trainer_name }}</p>
        </div> -->

        <div class="d-flex catalog--item__description--data">
          <p class="trainings">
            <span
              :class="`type_${program.levelValue}`"
              title="Уровень сложности"
            ></span>
          </p>
          <p
            class="d-flex"
            style="margin-right: 8px"
            title="Количество тренировок"
          >
            <i class="menu-icon flaticon2-chronometer"></i>
            {{ program.trainings_count }}
          </p>
          <p
            class="catalog--item__header--users-couter d-flex"
            title="Занимаются по программе"
          >
            <i class="menu-icon flaticon2-user"></i>
            {{ program.count_done_trainings }}
          </p>
        </div>
      </div>
      <!-- <div class="catalog--footer">
        <div class="catalog--item__description--info-box">
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FSProgramItem",
  props: {
    program: Object,
    userSubscriptions: Object,
    indexProgram: Number,
    indexCategory: Number,
    isTrial: Boolean
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    programActiveAt(program) {
      let date;

      date = moment(program.created_at);

      if (program.days_after_create && program.days_after_create > 0) {
        date.add(program.days_after_create, "days");
      }

      return date.format("Y.MM.DD");
    }
  }
};
</script>
