<template>
	<div class="upgrade-panel">
		<div class="upgrade-panel-close" @click="$emit('close')"></div>
		<a :href="`${app_url}subscribe?promo=${upgrade.promocode.code}&tariff=${upgrade.tariff.id}&session=screen&upgrade_order_id=${upgrade.order_id}`">Получи <strong>{{upgrade.tariff.name}} и подарки</strong> <span>{{upgrade.old_price}} р.</span>  <strong>за {{upgrade.new_price}} руб.</strong> <template v-if="timeLeft">До конца акции: <strong>{{ timeLeft.days }} д. {{ timeLeft.hours }} ч. {{ timeLeft.minutes }} м.</strong></template></a>
	</div>
</template>

<script>
	import setings from '../settings'
	export default {
		name: "Upagrade",
		props: {
			upgrade: {
				type: Object,
			}
		},
		data() {
			return {
				timeLeft: null,
				timer: null,
				app_url: null,
			};
		},
		methods: {
			calculateTimeLeft() {
				const now = new Date().getTime();
				const target = new Date(this.upgrade.last_upgrade_date).getTime();
				const distance = target - now;

				if (distance <= 0) {
					clearInterval(this.timer);
					this.timeLeft = null;
				} else {
					this.timeLeft = {
						days: Math.floor(distance / (1000 * 60 * 60 * 24)),
						hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
						minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
					};
				}
			}
		},
		created() {
			this.calculateTimeLeft();
			this.timer = setInterval(this.calculateTimeLeft, 1000);
			this.app_url = setings.APP_URL;
		},
		beforeDestroy() {
			clearInterval(this.timer);
		}
	}
</script>

<style>
	.upgrade-panel {
		background-color: #69A6C3;
		position: fixed;
		z-index: 1000;
		width: 100%;
		height: 38px;
		color: #fff;
		text-align: center;
	}
	.upgrade-panel a {
		color: #fff;
		text-decoration: none;
		font-size: 18px;
		line-height: 38px;
	}
	.upgrade-panel span {
		text-decoration: line-through;
	}
	.upgrade-panel-close {
		background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.06 5.06L20 4L12.53 11.469L5.06 4L4 5.06L11.469 12.53L4 20L5.06 21.06L12.53 13.591L20 21.06L21.06 20L13.591 12.53L21.06 5.06Z' fill='white'/%3E%3C/svg%3E%0A");
		position: absolute;
		right: 10px;
		cursor: pointer;
		top: 0;
		width: 38px;
		height: 38px;
		background-size: 25px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
</style>