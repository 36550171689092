<template>
  <ul class="menu-nav">
    <!-- BEGIN: Мой профиль -->
    <router-link :to="`/fitness-profile/${userData.url_slug}`" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user"></i>
          <span class="menu-text">Мой профиль</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мой профиль -->
    <!-- BEGIN: Мои программы -->
    <router-link to="/programs/my" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-2"></i>
          <span class="menu-text">Мои программы</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои программы -->
    <!-- BEGIN: Мои курсы -->
    <router-link to="/courses/my" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-folder"></i>
          <span class="menu-text">Мои курсы</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои курсы -->
    <!-- BEGIN: Мои тренировки -->
    <router-link to="/training" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-chronometer"></i>
          <span class="menu-text">Мои тренировки</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои тренировки -->
    <!-- BEGIN: Питание -->
    <!--<router-link
      to="/recipes"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-tea-cup"></i>
          <span class="menu-text">Питание</span>
        </a>
      </li>
    </router-link> -->
    <!-- END: Питание -->
    <router-link to="/progress" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cup"></i>
          <span class="menu-text">Мой прогресс</span>
        </a>
      </li>
    </router-link>
    <router-link to="/favorites" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-star"></i>
          <span class="menu-text">Избранные тренировки</span>
        </a>
      </li>
    </router-link>
    <router-link to="/orders" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon far fa-credit-card"></i> -->
          <i class="menu-icon flaticon-price-tag"></i>
          <span class="menu-text">Моя подписка</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/comments"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-speech-bubble"></i>
          <span class="menu-text">Мои коментарии</span>
        </a>
      </li>
    </router-link> -->
    <!-- BEGIN: Разделитель -->
    <li class="menu-item menu-item--separator">
      <span class="menu-link">
        <span class="menu-text border-top"></span>
      </span>
    </li>
    <!-- END: Разделитель -->
    <!-- BEGIN: Подарочный сертификат  -->
    <li class="menu-item">
      <a target="_blank" :href="'https://fitstars.ru/certificate'" class="menu-link" style="align-items: center">
        <i class="menu-icon flaticon2-gift"></i>
        <span class="menu-text">Подарочный сертификат</span>
      </a>
    </li>
    <!-- END: Подарочный сертификат -->
    <!-- BEGIN: Пригласи друга -->
    <router-link to="/invite-friends" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-avatar"></i>
          <span class="menu-text">Пригласи друга</span>
        </a>
      </li>
    </router-link>
    <!-- END: Пригласи друга -->
    <!-- BEGIN: Интернет магазин -->
    <!--<li class="menu-item">
      <a
        target="_blank"
        :href="'https://plus.fitstars.ru/'"
        class="menu-link"
        style="align-items: center;"
      >
        <i class="menu-icon flaticon-shopping-basket"></i>
        <span class="menu-text">Интернет магазин</span>
      </a>
    </li> -->
    <!-- END: Интернет магазин -->
    <!-- BEGIN: Разделитель -->
    <li class="menu-item menu-item--separator">
      <span class="menu-link">
        <span class="menu-text border-top"></span>
      </span>
    </li>
    <!-- END: Разделитель -->
    <li class="menu-item" @click="onLogout">
      <a class="menu-link">
        <i class="menu-icon flaticon2-left-arrow-1"></i>
        <span class="menu-text">Выход</span>
      </a>
    </li>
    <li class="menu-item menu-item--switcher" v-if="userData.show_go_new_lk && userData.show_go_new_lk == 1">
      <a class="menu-link">
        <div class="menu-icon">
          <img src="@/assets/img/logo-small.svg" alt="" class="menu-icon__img">
        </div>
        <span class="menu-text">Новый дизайн </span>
        <span class="switch switch--menu">
          <label>
            <input type="checkbox" v-model="newLK" :disabled="isDisabledNewLk">
            <span></span>
          </label>
        </span>
      </a>
    </li>

    <div class="aside-app desk-aside-app">
      <div class="aside-app__title">
        Скачайте приложение:
      </div>
      <div class="aside-app__btn-wr">
        <div class="aside-app__btn"
          onclick="window.open('https://redirect.appmetrica.yandex.com/serve/99303992990976435', '_blank');">
          <img src="@/assets/img/icons-svg/google-play.svg">
        </div>
        <div class="aside-app__btn"
          onclick="window.open('https://redirect.appmetrica.yandex.com/serve/171361584696874164', '_blank');">
          <img src="@/assets/img/icons-svg/apple-play.svg">
        </div>
      </div>
    </div>
  </ul>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import { LOGOUT } from "@/core/services/store/auth.module";

import settings from "@/settings";
import {
  ADD_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "KTMenu",
  data() {
    return {
      newLK: false,
      isDisabledNewLk: false,
      isShowLoader: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    onLogout() {
      let __self__ = this;

      Swal.fire({
        title: "Вы уверены?",
        text: "Что хотите выйти из личного кабинета",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Выйти",
        confirmButtonColor: "#ef16b0",
        cancelButtonText: "Отменить",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        },
        reverseButtons: true
      }).then(function (result) {
        if (result.value) {
          __self__.$store.dispatch(LOGOUT).then(() => {
            window.location.href = settings.APP_URL;
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(["userData", "currentUserPersonalInfo","newLKInfo"])
  },
  watch: {
    userData() {
      if (this.userData.name) {
        this.userName = `${this.userData.name} (${this.userData.email})`;
        this.userNameFirstLatter = this.userData.name.charAt(0).toUpperCase();
      } else {
        this.userName = this.userData.email;
        this.userNameFirstLatter = this.userData.email.charAt(0).toUpperCase();
      }
    },
    async newLK() {
      this.isDisabledNewLk = true;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      await this.$store.dispatch('goToNewLk');
      if (this.newLKInfo && this.newLKInfo.data && this.newLKInfo.data.api_v4_token) {
        window.location.href = `${settings.NEW_APP_URL}?token=${this.newLKInfo.data.api_v4_token}`
      }
    }
  }
};
</script>

<style>
.aside-app.desk-aside-app {
  position: relative;
  display: block;
  margin-top: 40px;
}
.switch--menu input:empty~span {
  height: 16px;
  width: 36px;
}
.switch--menu input:empty~span:before {
  width: 36px;
  border: 0.56px solid #c1c1c1;
}
.switch--menu input:empty~span:after {
  width: 13px;
  height: 13px;
  top: 1px;
  margin-left: 2px;
}
.switch--menu input:checked~span:after {
  margin-left: 21px;
}
.switch--menu input:checked~span:before {
  border: 0.56px solid #EF16B0;
}
.aside-menu .menu-nav {
  overflow-x: hidden;
}
.header-menu-wrapper .menu-item--switcher {
  display: none !important;
}
</style>
