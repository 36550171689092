import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "./auth.service";

const basePath = `${settings.API_URL}`;
const basePathV2 = `${settings.API_URL_V2}`;

export default {
  getRecipe(id, successCallback, errorCallback) {
    instance
      .get(`${basePathV2}recipes/${id}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipeRatingUser(id, successCallback, errorCallback) {
    instance
      .get(`${basePath}user/rating/recipe/${id}`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getRecipeComments(id, successCallback, errorCallback) {
    instance
      .get(`${basePathV2}/recipe/${id}/comments`, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
