<template>
  <div>
    <div v-if="trainings.length" class="bg-white bg-white--transparent padding-20px">
      <div class="portlet-title">
        <div class="caption">
          <span class="caption-subject bold uppercase">
            Твои избранные тренировки
          </span>
        </div>
      </div>
      <div class="flex flex--wrap">
        <div
          class="flex favorite-item"
          v-for="training in trainings"
          :key="`training-id-${training.id}`"
        >
          <div class="padding-15px-horizontal">
            <router-link
              :to="`/training/${training.program_id}/${training.id}`"
              class="favorite-image"
            >
              <img
                :src="
                  `https://img.youtube.com/vi/${getYoutubeId(
                    training.video
                  )}/mqdefault.jpg`
                "
                class="img-responsive"
                alt=""
              />
            </router-link>
          </div>
          <div class="padding-15px-horizontal">
            <div class="favorite-info">
              <strong>
                <router-link
                  :to="`/training/${training.program_id}/${training.id}`"
                  class="favorite-link"
                >
                  {{ training.name }}
                </router-link>
              </strong>
              <br />
              <small>{{ training.program_name }}</small>
              <div class="favorite-delete">
                <a data-toggle="modal" :href="`#remove_${training.id}`">
                  <i
                    class="far fa-trash-alt font-grey-gallery"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            class="modal fade"
            :id="`remove_${training.id}`"
            role="modal"
            tabindex="-1"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    aria-hidden="true"
                    class="close"
                    data-dismiss="modal"
                    type="button"
                  ></button>
                  <h4 class="modal-title">
                    <strong>Ты действительно хочешь удалить тренировку?</strong>
                  </h4>
                </div>
                <div class="modal-body">{{ training.name }}</div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    class="btn red"
                    data-dismiss="modal"
                    @click="removeFavoriteTraining(training.id)"
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

      <div class="fs-ptile--programs-my fs-ptile--wrap mt-10">
        <div>
          <h5 class="fs-ptile-title mb-8 mt-8 mr-8 ml-8" style="width: 100%">
            Твои избранные тренировки
          </h5>
        </div>
        <div class="fs-ptile-line"></div>
        <div class="mb-8 mt-8 mr-8 ml-8" style="width: 100%">
          Тренировки, которые ты добавляешь в избранное появляются в этом разделе после нажатия на звёздочку под тренировкой.
        </div>
        <div class="mb-8 ml-8">
          <router-link to="/trainings" v-slot="{ href, navigate }">
            <a :href="href" class="btn btn-success mr-2" @click="navigate">
              Перейти к тренировкам
            </a>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getYoutubeId } from "@/assets/js/helpers";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "PageFavorites",
  components: {
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      trainings: [],
      messages: [],
      errors: [],
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters.isTrainingLoading;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
  },
  methods: {
    getFavouriteTrainings() {
      this.$store.dispatch("getFavoriteTrainings").then(response => {
        this.trainings = response.data;

        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
      });
    },
    removeFavoriteTraining(id) {
      this.$store
        .dispatch("setFavoriteTraining", {
          id,
          state: false
        })
        .then(() => {
          this.getFavouriteTrainings();
        });
    },
    getYoutubeId
  },
  mounted() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Тренировки", route: "/programs" },
      { title: "Избранные" }
    ]);
  },
  created() {
    this.getFavouriteTrainings();
  },
  setPageData() {
    switch (this.socialIsMyProfile) {
      case true:
        this.pageTitle = "Избранные - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Избранные" }]);

        break;
      case false:
        this.pageTitle = "Избранные - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "Избранные - FitStars.ru"
          }
        ]);

        break;
    }
  }
};
</script>
