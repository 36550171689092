<template>
  <div class="row">
    <div class="col-xl-2"></div>
    <!--begin::Card-->
    <div class="col-xl-7 my-2">
      <div class="card-body">
        <!--begin::Form-->
        <form class="form" id="kt_personal_information">
          <!--begin::Body-->
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-9">
              <h5 class="font-weight-bold mb-6">Личные данные</h5>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Аватар
            </label>
            <div class="col-9">
              <div
                class="image-input image-input-outline"
                id="kt_profile_avatar"
                v-if="!photoIsLoaded"
              >
                <div
                  class="image-input-wrapper"
                  :style="{ backgroundImage: `url(${avatar_photo})` }"
                ></div>
                <label
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input
                    type="file"
                    name="profile_avatar"
                    accept=".png, .jpg, .jpeg"
                    @change="onFileChange($event)"
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title="Cancel avatar"
                >
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                <span
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title="Remove avatar"
                  @click="removeAvatar"
                >
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
              <span class="form-text text-muted" v-if="!photoIsLoaded">
                Допустимые типы файлов: png, jpg, jpeg.
              </span>
              <div
                class="image-input image-input-outline"
                id="kt_profile_avatar"
                v-if="photoIsLoaded"
              >
                <vue-cropper
                  class="image-input-wrapper"
                  :aspect-ratio="1 / 1"
                  :guides="true"
                  :min-crop-box-width="200"
                  :src="crop_photo"
                  :view-mode="3"
                  drag-mode="crop"
                  ref="cropper"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Имя
            </label>
            <div class="col-9">
              <input
                ref="name"
                name="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Фамилия
            </label>
            <div class="col-9">
              <input
                ref="surname"
                name="surname"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="surname"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Скрыть профайл
            </label>
            <div class="col-9">
              <span class="switch">
                <label>
                  <input
                    type="checkbox"
                    ref="is_blocked"
                    name="is_blocked"
                    v-model="is_blocked"
                  />
                  <span></span>
                </label>
              </span>
              <span class="form-text text-muted">
                Выкл/Вкл отображения данных для других пользователей
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Автоматическая кнопка "готово"
            </label>
            <div class="col-9">
              <span class="switch">
                <label>
                  <input
                    type="checkbox"
                    ref="auto_complite_training"
                    name="auto_complite_training"
                    v-model="auto_complite_training"
                  />
                  <span></span>
                </label>
              </span>
              <span class="form-text text-muted">
                Мы отметим тренировку как выполненную, если будет просмотрено
                более половины
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Использовать YouTube по умолчанию
            </label>
            <div class="col-9">
              <span class="switch">
                <label>
                  <input
                    type="checkbox"
                    ref="show_youtube_player"
                    name="show_youtube_player"
                    v-model="show_youtube_player"
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-9">
              <h5 class="font-weight-bold mt-10 mb-6">Контактная информация</h5>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Телефон
            </label>
            <div class="col-9">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-phone"></i>
                  </span>
                </div>
                <input
                  ref="iu_telephone"
                  name="iu_telephone"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Phone"
                  v-bind:value="iu_telephone"
                />
              </div>
              <!-- <span class="form-text text-muted">
                We'll never share your email with anyone else.
              </span> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Email
            </label>
            <div class="col-9">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-at"></i>
                  </span>
                </div>
                <input
                  ref="email"
                  name="email"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Email"
                  v-bind:value="email"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Instagram
            </label>
            <div class="col-9">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la fab la-instagram"></i>
                  </span>
                </div>
                <input
                  ref="instagram"
                  name="instagram"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="instagram.com/"
                  v-bind:value="instagram"
                />
              </div>
            </div>
          </div>
          <!--end::Body-->
        </form>
      </div>
      <div class="card-footer card-footer--settings d-flex flex-column align-items-center pb-0">
        <div class="d-flex flex-column">
          <button
            type="submit"
            class="btn btn-success mb-7"
            @click="save()"
            ref="kt_save_changes"
          >
            Изменить
          </button>
          <a
            @click="$emit('questionnaireOpen')"
            href="javascript:void(0)"
            class="text-decoration-underline"
          >
            Пройти анкетирование повторно
          </a>
        </div>
      </div>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
// Settings
// import settings from "@/settings";
import VueCropper from "vue-cropperjs";

export default {
  name: "PersonalInformation",
  components: {
    VueCropper
  },
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      iu_telephone: "",
      is_blocked: false,
      instagram: "",
      photoIsLoaded: false,
      crop_photo: null,
      avatar_photo: null,
      current_photo: null,
      default_photo: "media/users/blank.png",
      auto_complite_training: false,
      show_youtube_player: false
    };
  },
  computed: {
    ...mapGetters(["userData"])
  },
  mounted() {
    // const __self__ = this;
    const personal_information_form = KTUtil.getById("kt_personal_information");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Редактировать профиль" }]);
    this.$store.dispatch("getUser");
    this.fv = formValidation(personal_information_form, {
      fields: {
        // name: {
        //   validators: {
        //     notEmpty: {
        //       message: "Необходимо заполнить данные"
        //     }
        //   }
        // },
        // surname: {
        //   validators: {
        //     notEmpty: {
        //       message: "Выбери уровень активности"
        //     }
        //   }
        // },
        email: {
          validators: {
            emailAddress: {
              message: "Введи корректный email"
            }
          }
        }
        // iu_telephone: {
        //   validators: {
        //     notEmpty: {
        //       message: "Необходимо заполнить данные"
        //     }
        //   }
        // },
        // instagram: {
        //   validators: {
        //     notEmpty: {
        //       message: "Необходимо заполнить данные"
        //     }
        //   }
        // }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_save_changes"];
      const is_blocked = this.is_blocked;
      const name = this.$refs.name.value;
      const surname = this.$refs.surname.value;
      const instagram = this.$refs.instagram.value;
      const iu_telephone = this.$refs.iu_telephone.value;
      const email = this.$refs.email.value;
      const auto_complite_training = this.auto_complite_training;
      const show_youtube_player = this.show_youtube_player;

      // set spinner to submit button
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let payload = {
        is_blocked: is_blocked,
        name: name,
        surname: surname,
        instagram: instagram,
        iu_telephone: iu_telephone,
        email: email,
        username: email,
        auto_complite_training: auto_complite_training,
        show_youtube_player: show_youtube_player
      };

      if (this.photoIsLoaded === true) {
        this.$refs.cropper.getCroppedCanvas({ width: 200 }).toBlob(blob => {
          const formData = new FormData();

          formData.append("file", blob, "image.png");

          this.$store
            .dispatch("updateUserAvatar", { formData })
            .then(() => {
              this.photoIsLoaded = false;
              this.crop_photo = null;
              this.updateUser(payload, submitButton);
              this.$store.dispatch("getUserUpdateAvatar");
            })
            .catch(() => {});
        });
      } else {
        this.updateUser(payload, submitButton)
      }
    });
  },
  methods: {
    removeAvatar() {
      if (this.userData && this.userData.avatar) {
        this.$store
        .dispatch("deleteUserAvatar")
        .then(() => {
          this.$store.dispatch("getUser");

          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 5000);
          
          this.$notify({
            type: "success",
            title: "Ваше фото успешно удалено"
          });
        })
        .catch((e) => {
          if (e.response?.data?.data) {
            this.$notify({
              type: "error",
              title: e.response.data.data,
            });
          }
        });
      }
  },

    updateUser(payload, submitButton) {
      this.$store
        .dispatch("updateUser", payload)
        .then(() => {
          this.$store.dispatch("getUser");

          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 5000);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch((e) => {
          if (e.response?.data?.data) {
            this.$notify({
              type: "error",
              title: e.response.data.data,
            });

            if (e.response.data.data.toUpperCase().includes('EMAIL')) {
              this.fv.updateFieldStatus('email', 'Invalid');
            }
          }

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    save() {
      this.fv.validate();
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.photoIsLoaded = true;
          this.avatar_photo = event.target.result;
          this.crop_photo = event.target.result;

          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  watch: {
    userData() {
      this.is_blocked =
        this.userData.is_blocked && this.userData.is_blocked == 1
          ? true
          : false;
      this.name = this.userData.name;
      this.surname = this.userData.surname;
      this.iu_telephone = this.userData.iu_telephone;
      this.email = this.userData.email;
      this.instagram = this.userData.instagram;
      this.auto_complite_training =
        this.userData.auto_complite_training &&
        this.userData.auto_complite_training == 1
          ? true
          : false;
      this.show_youtube_player =
        this.userData.show_youtube_player &&
        this.userData.show_youtube_player == 1
          ? true
          : false;

      if (this.userData && this.userData.avatar) {
        this.current_photo = `${this.userData.avatar.path}`;
        this.avatar_photo = `${this.userData.avatar.path}`;
      } else {
        this.current_photo = this.default_photo;
        this.avatar_photo = this.default_photo;
      }
    }
  }
};
</script>

<style scoped></style>
