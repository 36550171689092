const APP_VERSION = "v 0.0.1";

// begin: PROD
let API_URL_V2 = "https://fitstars.ru/api/web/v2/";
let API_URL = "https://fitstars.ru/api/v1/";
let APP_URL = "https://fitstars.ru/";
let NEW_APP_URL = "https://new.fitstars.ru/";
// end: PROD
// begin: STAGING
// let API_URL_V2 = "https://fs111.nwh.ru/api/web/v2/";
// let API_URL = "https://fs111.nwh.ru/api/v1/";
// let APP_URL = "https://fs111.nwh.ru/";
// let NEW_APP_URL = "https://nuxt.nwh.ru/";
// end: STAGING
// begin: LOCAL
//let API_URL_V2 = "http://127.0.0.1:8000/api/web/v2/";
//let API_URL = "http://127.0.0.1:8000/api/v1/";
//let APP_URL = "http://127.0.0.1:8000/";
//let NEW_APP_URL = "https://nuxt.nwh.ru/";
// end: LOCAL

const settings = {
  APP_VERSION,
  API_URL_V2: `${API_URL_V2}`,
  API_URL: `${API_URL}`,
  APP_URL: `${APP_URL}`,
  NEW_APP_URL: `${NEW_APP_URL}`,
  ID_TOKEN: "token",
  REFRESH_TOKEN: "refresh_token",
};

export default settings;
