<template>
  <div
    v-if="recipe && indexCategory >= 0 && indexRecipe >= 0"
    class="catalog--item__link"
  >
    <div class="catalog-item-wrapper">
      <div
        v-if="
          (!userSubscriptions && !isTrial &&
            (!recipe || (recipe && recipe.tariff == null))) && !recipe.is_trial ||
            (recipe.hide_for_user && recipe.hide_for_user == true)
        "
        class="catalog--item__image catalog--item__image--program no-subscription"
      >
        <div class="no-subscription-bg"></div>
        <div class="no-subscription-wr">
          <img
            class="no-subscription-img"
            src="@/assets/img/icons-svg/lock-programs.svg"
            alt=""
          />
          <p
            v-if="recipe.days_after_create && recipe.days_after_create > 0 && userSubscriptions"
            class="no-subscription-text"
          >
            Станет доступен {{ programActiveAt(program) }}
          </p>
          <p
            v-else
            class="no-subscription-text"
          >
            Рецепт недоступен
          </p>
        </div>
        <picture>
          <source :srcset="`${recipe.thumbs.webp}`" type="image/webp">
          <source :srcset="`${recipe.thumbs.jpg}`" type="image/jpeg"> 
          <img class="img" :src="`${recipe.thumbs.jpg}`" />
        </picture>
      </div>
      <div v-else class="catalog--item__image">
        <picture>
          <source :srcset="`${recipe.thumbs.webp}`" type="image/webp">
          <source :srcset="`${recipe.thumbs.jpg}`" type="image/jpeg"> 
          <img class="img" :src="`${recipe.thumbs.jpg}`" />
        </picture>
      </div>
      <div class="catalog--item__description">
        <p class="nutrition-list-item-info-description">
          {{ recipe.type.name }}
        </p>
        <p class="catalog--item__description--name">
          {{ recipe.name }}
        </p>
        <div class="d-flex catalog--item__description--data">
          <div class="kkal">{{ recipe.kkal }} Ккал</div>
          <div class="cook-time">
            <img
              class="disctop-icon"
              src="@/assets/img/recipe/new/cock-time.svg"
              alt=""
            />
            <img class="mobile-icon" src="@/assets/img/icon-clock.png" alt="" />
            {{ recipe.cook_time }}
          </div>
          <div class="rating">
            <img
              class="disctop-icon"
              src="@/assets/img/recipe/new/star.svg"
              alt=""
            />
            <img class="mobile-icon" src="@/assets/img/icon-star.png" alt="" />
            <template v-if="recipe.rating">{{ Number(recipe.rating).toFixed(1) }}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FSRecipeItem",
  props: {
    recipe: Object,
    userSubscriptions: Object,
    indexRecipe: Number,
    indexCategory: Number
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
