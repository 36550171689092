<template>
  <div class="row">
    <div class="col-xl-2"></div>
    <!--begin::Card-->
    <div class="col-xl-7 my-2">
      <div class="card-body">
        <!--begin::Form-->
        <form class="form" id="kt_password_change_form">
          <!--begin::Alert-->
          <div
            v-show="success"
            class="alert alert-custom alert-light-success fade show mb-10"
            role="alert"
            ref="message"
          >
            <div class="alert-icon">
              <span class="svg-icon svg-icon-3x svg-icon-success">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle
                      fill="#000000"
                      opacity="0.3"
                      cx="12"
                      cy="12"
                      r="10"
                    />
                    <rect
                      fill="#000000"
                      x="11"
                      y="10"
                      width="2"
                      height="7"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      x="11"
                      y="7"
                      width="2"
                      height="2"
                      rx="1"
                    />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <div class="alert-text font-weight-bold">
              Пароль успешно изменен
            </div>
            <div class="alert-close">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i class="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
          <!--end::Alert-->
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Текущий пароль
            </label>
            <div class="col-9">
              <input
                type="password"
                class="form-control form-control-lg form-control-solid mb-2"
                value=""
                placeholder="Текущий пароль"
                name="old_password"
                ref="old_password"
              />
              <!-- <a href="#" class="text-sm font-weight-bold">Забыл пароль?</a> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Новый пароль
            </label>
            <div class="col-9">
              <input
                type="password"
                class="form-control form-control-lg form-control-solid"
                value=""
                placeholder="Новый пароль"
                name="new_password"
                ref="new_password"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Подтверждение нового пароля
            </label>
            <div class="col-9">
              <input
                type="password"
                class="form-control form-control-lg form-control-solid"
                value=""
                placeholder="Подтверждение нового пароля"
                name="verify_password"
                ref="verify_password"
              />
            </div>
          </div>
        </form>
        <!--end::Form-->
      </div>
      <div class="card-footer card-footer--settings d-flex flex-column align-items-center pb-0">
        <div class="d-flex flex-column">
          <button
            type="submit"
            class="btn btn-success mb-7"
            @click="save()"
            ref="kt_save_changes"
          >
            Изменить
          </button>
          <a
            @click="$emit('questionnaireOpen')"
            href="javascript:void(0)"
            class="text-decoration-underline"
          >
            Пройти анкетирование повторно
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
// import Swal from "sweetalert2";

import $ from "jquery";

export default {
  name: "ChangePassword",
  data() {
    return {
      success: false,
      errors: {},
      processing: false,
      valid: false,
      validators: {}
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    closeSuccess() {
      this.success = false;
    },
    save() {
      this.fv.validate();
    },
    validatePasswordfromError() {
      const __self__ = this;

      return {
        validate: function(input) {
          const field = input.field;

          if (__self__.errors && __self__.errors[field]) {
            return {
              valid: false,
              message: __self__.errors[field]
            };
          }

          return {
            valid: true
          };
        }
      };
    }
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Сменить пароль" }]);
    this.$store.dispatch("getUser");
    this.fv = formValidation(password_change_form, {
      fields: {
        old_password: {
          validators: {
            notEmpty: {
              message: "Текущий пароль не может быть пустым"
            },
            validateErrors: {
              message: "Пароль неверный"
            }
          }
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "Новый пароль не может быть пустым"
            },
            different: {
              compare: function() {
                return password_change_form.querySelector(
                  "[name='old_password']"
                ).value;
              },
              message: "Новый пароль должен отличаться от текущего"
            },
            stringLength: {
              min: 4,
              message: "Пароль должен быть не менее 4-х символов"
            },
            validateErrors: {
              message: "Пароль неверный"
            }
          }
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Подтвержденный пароль не может быть пустым"
            },
            identical: {
              compare: function() {
                return password_change_form.querySelector(
                  "[name='new_password']"
                ).value;
              },
              message: "Пароль и его подтверждение не совпадают"
            },
            validateErrors: {
              message: "Пароль неверный"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    }).registerValidator("validateErrors", this.validatePasswordfromError);

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_save_changes"];
      const passwordOld = this.$refs.old_password
        ? this.$refs.old_password.value
        : "";
      const passwordNew = this.$refs.new_password
        ? this.$refs.new_password.value
        : "";
      const passwordVerify = this.$refs.verify_password
        ? this.$refs.verify_password.value
        : "";

      // set spinner to submit button
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      this.$store
        .dispatch("updateUserPassword", {
          old_password: passwordOld,
          new_password: passwordNew,
          new_password_confirm: passwordVerify
        })
        .then(() => {
          this.fv.resetForm(true);

          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 5000);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch(error => {
          const __self__ = this;

          this.errors = { old_password: error };
          $.each(this.errors, key => {
            __self__.fv.validateField(key).then(() => {
              delete __self__.errors[key];
            });
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  }
};
</script>

<style scoped></style>
